import React, { Component } from "react";

import List from "@material-ui/core/List";

import ChatItem from "./ChatItem";

import { withFirebase } from "../Firebase";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  messageArea: {
    overflowY: "auto",
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      borderRightStyle: "solid",
      borderRightWidth: 1,
      borderRightColor: "#000000",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
    },
  },
  itemPrimaryLeft: {
    color: "white",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  itemPrimaryRight: {
    color: "white",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  itemSecondaryLeft: {
    color: "white",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  itemSecondaryRight: {
    color: "white",
    fontSize: theme.typography.fontSize,
    "&$textDense": {
      fontSize: theme.typography.fontSize,
    },
  },
  itemTime: {
    color: "gray",
    fontSize: 12,
    "&$textDense": {
      fontSize: 12,
    },
  },
});

class ChatComponent extends Component {
  constructor() {
    super();

    this.state = {
      nicknames: {},
      message: null,
    };
  }

  async componentDidMount() {
    let tmp = this.props.responses;
    let processing = [];
    let namePromises = [];
    let names = {};
    for (let i = 0; i < tmp.length; i++)
      if (
        !this.state.nicknames.hasOwnProperty(tmp[i].answeringUser) &&
        processing.indexOf(tmp[i].answeringUser) == -1
      ) {
        processing.push(tmp[i].answeringUser);
        namePromises.push(
          this.props.firebase
            .user2nickname(
              this.props.firebase.workgroupPath,
              tmp[i].answeringUser
            )
            .then((name) => {
              names[tmp[i].answeringUser] = name;
            })
        );
      }

    Promise.all(namePromises).then(() => {
      if (Object.keys(names).length > 0)
        this.setState({
          nicknames: { ...this.state.nicknames, ...names },
        });
    });
  }

  componentWillUnmount() {
    // console.log(this.props.chatRef + " unmount");
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let tmp = nextProps.responses;
    let processing = [];
    let namePromises = [];
    let names = {};
    for (let i = 0; i < tmp.length; i++)
      if (
        !this.state.nicknames.hasOwnProperty(tmp[i].answeringUser) &&
        processing.indexOf(tmp[i].answeringUser) == -1
      ) {
        processing.push(tmp[i].answeringUser);
        namePromises.push(
          this.props.firebase
            .user2nickname(
              this.props.firebase.workgroupPath,
              tmp[i].answeringUser
            )
            .then((name) => {
              names[tmp[i].answeringUser] = name;
            })
        );
      }

    Promise.all(namePromises).then(() => {
      if (Object.keys(names).length > 0)
        this.setState({
          nicknames: names,
        });
    });
  }

  render() {
    const { classes, myUID, responses } = this.props;
    // console.log(responses);
    return (
      <List disablePadding className={classes.messageArea}>
        {responses.map((item, index) => (
          <ChatItem
            key={item.id}
            nickname={this.state.nicknames[item.answeringUser]}
            item={item}
            index={index}
            myUID={myUID}
            chatRef={this.props.chatRef}
          />
        ))}
      </List>
    );
  }
}

export default withStyles(styles)(withFirebase(ChatComponent));
