import React, { Component } from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";

import IconButton from "@material-ui/core/IconButton";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import TextField from "@material-ui/core/TextField";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RefreshIcon from "@material-ui/icons/Refresh";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import CircularProgress from "@material-ui/core/CircularProgress";

import Timestamp from "../Timestamp";

import * as Lists from "../Lists.js";
import * as Helper from "../../Helper";

import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers";

import { withFirebase } from "../Firebase";
import { withAuthorization } from "../Session";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";

//import RefreshIcon from '@material-ui/icons/Refresh';

class AItrainingTool extends Component {
  constructor() {
    super();

    this.state = {
      data: null,
      listOfNotTrainedPathologies: null,
      progress: null,
      classiferResults: null,
      classiferPremiumResults: null,
      selectedCrop: null,
      cropsInStagingProcess: [],
      changedSinceLastTraining: {},
    };
  }

  async componentDidMount() {
    let obj = {};

    let promises = [];

    promises.push(
      this.props.firebase.db
        .ref(
          this.props.firebase.workgroupPath +
            "/treatmentProtocols/trainedVectorsDates"
        )
        .once("value")
        .then((result) => {
          return result.val();
        })
        .catch((error) => {
          console.log(error);
        })
    );

    promises.push(
      this.props.firebase.db
        .ref(
          this.props.firebase.workgroupPath +
            "/treatmentProtocols/diseaseInfoImages"
        )
        .once("value")
        .then((result) => {
          return result.val();
        })
        .catch((error) => {
          console.log(error);
        })
    );

    let classiferDateRef =
      this.props.firebase.workgroupPath === ""
        ? "FBfunctionResults/classify/train/public"
        : "FBfunctionResults/classify/train/" + this.props.firebase.workgroupID;

    promises.push(
      this.props.firebase.db
        .ref(classiferDateRef)
        .once("value")
        .then((result) => {
          let data = result.val();
          if (data) {
            let ps = [];
            let finalResult = {};
            let crops = Object.keys(data);
            for (let k = 0; k < crops.length; k++) {
              let promise = this.processTrainResults(
                classiferDateRef + "/" + crops[k],
                data[crops[k]]
              )
                .then((snap) => {
                  finalResult[crops[k]] = snap;
                })
                .catch((error) => {
                  console.log(error);
                });
              ps.push(promise);
            }
            return Promise.all(ps).then(() => {
              return finalResult;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
    );

    let classiferPremiumDateRef =
      this.props.firebase.workgroupPath === ""
        ? "FBfunctionResults/classify/trainPremium/public"
        : "FBfunctionResults/classify/trainPremium/" +
          this.props.firebase.workgroupID;

    promises.push(
      this.props.firebase.db
        .ref(classiferPremiumDateRef)
        .once("value")
        .then((result) => {
          let data = result.val();
          // console.log(data);
          if (data) {
            let ps = [];
            let finalResult = {};
            let crops = Object.keys(data);
            for (let k = 0; k < crops.length; k++) {
              let promise = this.processTrainResults(
                classiferPremiumDateRef + "/" + crops[k],
                data[crops[k]]
              )
                .then((snap) => {
                  finalResult[crops[k]] = snap;
                })
                .catch((error) => {
                  console.log(error);
                });
              ps.push(promise);
            }
            return Promise.all(ps).then(() => {
              return finalResult;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
    );

    promises.push(
      this.props.firebase.db
        .ref(
          this.props.firebase.workgroupPath +
            "/treatmentProtocols/changedSinceLastTraining/free"
        )
        .once("value")
        .then((result) => {
          return result.val();
        })
    );

    Promise.all(promises)
      .then((result) => {
        let notTrained = {};
        for (let cropKey in result[1]) {
          notTrained[cropKey] = [];
          for (let diseaseKey in result[1][cropKey])
            if (
              result[0].hasOwnProperty(cropKey) == false ||
              result[0][cropKey].hasOwnProperty(diseaseKey) == false
            )
              notTrained[cropKey].push(diseaseKey);
        }

        this.setState({
          data: result[0],
          diseaseInfoImages: result[1],
          listOfNotTrainedPathologies: notTrained,
          classiferResults: result[2],
          classiferPremiumResults: result[3],
          changedSinceLastTraining: result[4] ? result[4] : {},
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentWillReceiveProps(nextProps) {}

  componentWillUnmount() {}

  processTrainResults = (classiferDateRef, raw) => {
    let ref = classiferDateRef.replace("train", "trainHistory");
    return this.props.firebase.db
      .ref(ref)
      .orderByKey()
      .endAt((raw.dateForSorting - 1).toString())
      .limitToLast(1)
      .once("value")
      .then((snap) => {
        let newObj = { ...raw };
        if (snap.val()) {
          newObj["accuracyChange"] =
            parseFloat(raw.accuracy) -
            parseFloat(Object.values(snap.val())[0].accuracy);
        }

        return newObj;
      });
  };

  handleTraining = async (trainedCrops) => {
    let date = new Date();
    let timestamp = date * 1;

    let classiferResults = JSON.parse(
      JSON.stringify(this.state.classiferResults)
    );
    let promises = [];

    let ref = this.props.firebase.db
      .ref("FBfunctionJobs/classify/train/")
      .child(this.props.firebase.workgroupID + timestamp);

    ref.remove().then(() => {
      ref
        .set({
          workgroupID: this.props.firebase.workgroupID,
          id: this.props.firebase.workgroupID + timestamp,
          cropArray: trainedCrops,
        })
        .then(() => {
          for (let ind in trainedCrops) {
            let crop = trainedCrops[ind];
            // console.log(crop);

            let classiferDateRef =
              this.props.firebase.workgroupPath === ""
                ? "FBfunctionResults/classify/train/public"
                : "FBfunctionResults/classify/train/" +
                  this.props.firebase.workgroupID;

            let lis = this.props.firebase.db.ref(classiferDateRef).child(crop);

            let promise = new Promise((resolve, reject) => {
              lis.on("value", (result) => {
                if (result.val()) {
                  lis.off();
                  return this.processTrainResults(
                    classiferDateRef,
                    result.val()
                  ).then((snap) => {
                    classiferResults[crop] = snap;
                    return resolve();
                  });
                }
              });
            });

            promises.push(promise);
          }

          return Promise.all(promises).then(() => {
            this.setState({ classiferResults: classiferResults });
          });
        });
    });
  };

  handleCleanTraining = () => {
    let promises = [];
    let classiferDateRef =
      this.props.firebase.workgroupPath === ""
        ? "FBfunctionResults/classify/train_staging/public"
        : "FBfunctionResults/classify/train_staging/" +
          this.props.firebase.workgroupID;

    promises.push(this.props.firebase.db.ref(classiferDateRef).remove());

    let ref =
      this.props.firebase.workgroupPath +
      "/treatmentProtocols/trainedVectors_staging";
    promises.push(this.props.firebase.db.ref(ref).remove());

    ref =
      this.props.firebase.workgroupPath + "/Labeling/tmpLabelVectors_staging";
    promises.push(this.props.firebase.db.ref(ref).remove());

    Promise.all(promises)
      .then(() => {
        console.log("cleaned");
      })
      .catch((err) => console.log(err));
  };

  handleStagingTraining = async () => {
    let trainedCrops = Object.keys(this.state.data);
    let cropsInProcess = Object.keys(this.state.data);
    this.setState({ cropsInStagingProcess: cropsInProcess });
    let classiferResults = JSON.parse(
      JSON.stringify(this.state.classiferResults)
    );
    let promises = [];

    for (let ind in trainedCrops) {
      let crop = trainedCrops[ind];

      let ref = this.props.firebase.db
        .ref("FBfunctionJobs/classify/train_staging/")
        .child(this.props.firebase.workgroupID + crop);

      let promise = ref.remove().then(() => {
        ref
          .set({
            workgroupID: this.props.firebase.workgroupID,
            id: this.props.firebase.workgroupID + crop,
            crop: crop,
          })
          .then(() => {
            let classiferDateRef =
              this.props.firebase.workgroupPath === ""
                ? "FBfunctionResults/classify/train_staging/public"
                : "FBfunctionResults/classify/train_staging/" +
                  this.props.firebase.workgroupID;

            let lis = this.props.firebase.db.ref(classiferDateRef).child(crop);

            return new Promise((resolve, reject) => {
              lis.on("value", (result) => {
                if (result.val()) {
                  lis.off();

                  let index = cropsInProcess.indexOf(crop);
                  if (index > -1) {
                    // only splice array when item is found
                    cropsInProcess.splice(index, 1); // 2nd parameter means remove one item only
                  }

                  return this.processTrainResults(
                    classiferDateRef,
                    result.val()
                  ).then((snap) => {
                    classiferResults[crop] = snap;
                    this.setState({
                      cropsInStagingProcess: [...cropsInProcess],
                    });
                    return resolve();
                  });
                }
              });
            });
          });
      });

      promises.push(promise);
    }

    return Promise.all(promises).then(() => {
      this.setState({ classiferStagingResults: classiferResults });
    });
  };

  handleStagingToProduction = () => {
    const input = prompt("Write 'Release' to confirm", "");
    if (input === "Release") {
      this.props.firebase.db
        .ref("/FBfunctionJobs/aiStagingToRelease")
        .child("id")
        .set(true)
        .then(() => {
          let listener = this.props.firebase.db
            .ref("/FBfunctionJobs/aiStagingToRelease")
            .child("id");
          listener.on("value", (result) => {
            if (result.val() == null) {
              listener.off();
              console.log("copied");
            }
          });
        });
    }
  };

  // Canceled as it is DONE OFFLINE
  //    let total = 0;
  //    let finish = 0;
  //
  //    let count = 0;
  //
  //    let workgroups = await this.props.firebase.db.ref("workgroupList")
  //     .once("value")
  //     .then(result => {
  //       let list = result.val();
  //       let active = [""];
  //       for (let key in list)
  //        if (list[key] === "active")
  //          active.push(key);
  //
  //       return active;
  //     });
  //
  //    for (let wgInd in workgroups) {
  //
  //    let workgroupPath = workgroups[wgInd] === "" ?
  //      "" :
  //      "workgroups/" + workgroups[wgInd];
  //
  //    await this.props.firebase.db.ref(workgroupPath + "/treatmentProtocols/training/trainedVectorsDates/")
  //     .once("value")
  //     .remove();
  //
  //    await this.props.firebase.db.ref(workgroupPath + "/treatmentProtocols/training/trainedVectors/")
  //     .once("value")
  //     .remove();
  //
  //    let currentVectors = await this.props.firebase.db.ref(workgroupPath + "/treatmentProtocols/trainedVectorsDates/")
  //     .once("value")
  //     .then(result => {
  //       return result.val();
  //     });
  //
  //    for (let host in currentVectors) {
  //
  //    for (let pathology in currentVectors[host]) {
  //
  //    let imagesData = await this.props.firebase.db
  //     .ref(workgroupPath + "/treatmentProtocols/diseaseInfoImages/")
  //     .child(host)
  //     .child(pathology)
  //     .once("value")
  //     .then(result => {
  //       return result.val()
  //     });
  //
  //    for (let i=0; i<imagesData.numImages; i++) {
  //
  //     count = count + 1;
  //     // console.log(count);
  //     total = total + 1;
  //
  //     let date = new Date();
  //     let dateForSorting = date*1;
  //
  //     let filename = workgroupPath === "" ?
  //     "diseaseInfo/" + host + "/" + imagesData.folderName + "/image" + (i + 1).toString() + ".jpg" :
  //     "diseaseInfo/" + workgroupPath + "/" + host + "/" + imagesData.folderName + "/image" + (i + 1).toString() + ".jpg";
  //
  //     let id = dateForSorting + "_" + host + "_" + pathology + "_" + i.toString();
  //
  //     let listener = this.props.firebase.db
  //     .ref("FBfunctionResults/classify/generic_train")
  //     .child(id);
  //
  //     listener.remove().then(() => {
  //       this.props.firebase.db
  //       .ref("FBfunctionJobs/classify/generic_train")
  //       .child(id)
  //       .set({
  //         bucket: "agrio-c2539.appspot.com",
  //         id: id,
  //         filename: filename
  //       });
  //     });
  //
  //     let promise = new Promise((resolve, reject) => {
  //
  //     listener.on("value", result => {
  //       if (result.val()) {
  //          listener.off();
  //
  //          this.props.firebase.db
  //            .ref(workgroupPath +
  //              "/treatmentProtocols/training/trainedVectors/" +
  //              host + "/" + pathology + "/vectors/" + i.toString()).set(result.val());
  //
  //          if (i == (imagesData.numImages - 1))
  //            this.props.firebase.db
  //            .ref(workgroupPath +
  //              "/treatmentProtocols/training/trainedVectorsDates/" +
  //              host + "/" + pathology).set(dateForSorting);
  //
  //          resolve()
  //       }
  //     });
  //     });
  //
  //     promise.then(() => {
  //       finish = finish + 1;
  //       this.setState({progress: finish/total});
  //     });
  //
  //
  //     }
  //    }
  //   }
  // }
  //
  //   this.setState({progress: null});
  //
  //
  //   }

  handleCropChange = async (event, selection) => {
    this.setState({ selectedCrop: selection });
  };

  render() {
    const { classes } = this.props;

    let trainedCrops =
      this.state.selectedCrop &&
      this.state.data &&
      this.state.selectedCrop in this.state.data
        ? [this.state.selectedCrop]
        : this.state.data && this.state.selectedCrop == null
        ? Object.keys(this.state.data)
        : [];

    let allCropsWithImage =
      this.state.selectedCrop &&
      this.state.listOfNotTrainedPathologies &&
      this.state.selectedCrop in this.state.listOfNotTrainedPathologies
        ? [this.state.selectedCrop]
        : this.state.listOfNotTrainedPathologies
        ? Object.keys(this.state.listOfNotTrainedPathologies)
        : [];

    let allCrops = this.state.listOfNotTrainedPathologies
      ? Object.keys(this.state.listOfNotTrainedPathologies)
      : [];

    let numTotalImage = 0;
    let numTotalCrops = 0;
    let numTotalProblems = 0;

    let minC = 100000;
    let maxC = 0;
    let minIter = 10000;
    let maxIter = 0;

    for (let item of trainedCrops) {
      numTotalCrops = numTotalCrops + 1;
      if (this.state.data && this.state.data.hasOwnProperty(item))
        for (let item2 in this.state.data[item]) {
          numTotalProblems = numTotalProblems + 1;
          numTotalImage =
            this.state.classiferResults &&
            this.state.classiferResults.hasOwnProperty(item) &&
            this.state.classiferResults[item].hasOwnProperty("numImages") &&
            this.state.classiferResults[item]["numImages"][item2] !== undefined
              ? numTotalImage +
                this.state.classiferResults[item]["numImages"][item2]
              : numTotalImage;

          if (
            this.state.classiferResults &&
            this.state.classiferResults.hasOwnProperty(item) &&
            this.state.classiferResults[item].hasOwnProperty("best params")
          ) {
            if (this.state.classiferResults[item]["best params"].C > maxC)
              maxC = this.state.classiferResults[item]["best params"].C;

            if (this.state.classiferResults[item]["best params"].C < minC)
              minC = this.state.classiferResults[item]["best params"].C;

            if (
              this.state.classiferResults[item]["best params"].max_iter >
              maxIter
            )
              maxIter =
                this.state.classiferResults[item]["best params"].max_iter;

            if (
              this.state.classiferResults[item]["best params"].max_iter <
              minIter
            )
              minIter =
                this.state.classiferResults[item]["best params"].max_iter;
          }
        }
    }

    const sortedCrops = trainedCrops.sort((a, b) => {
      let dateA = this.state.changedSinceLastTraining.hasOwnProperty(a)
        ? 0
        : this.state.classiferResults.hasOwnProperty(a)
        ? this.state.classiferResults[a].dateForSorting
        : 9999999999999;
      let dateB = this.state.changedSinceLastTraining.hasOwnProperty(b)
        ? 0
        : this.state.classiferResults.hasOwnProperty(b)
        ? this.state.classiferResults[b].dateForSorting
        : 9999999999999;

      return dateA - dateB; // This will sort the array in ascending order of dates
    });

    // console.log(sortedCrops);

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Paper
            className={classes.paper}
            style={{ display: "flex", flexDirection: "column", margin: 20 }}
          >
            <AppBar
              className={classes.searchBar}
              position="sticky"
              color="default"
              elevation={0}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: 12,
                  }}
                >
                  <Autocomplete
                    freeSolo
                    forcePopupIcon={true}
                    fullWidth={true}
                    autoSelect={true}
                    disableClearable={false}
                    maxMenuHeight={240}
                    size="small"
                    id="tag"
                    options={allCrops}
                    getOptionLabel={(option) => option}
                    defaultValue={[]}
                    onChange={this.handleCropChange}
                    value={this.state.selectedCrop}
                    renderInput={(params) => (
                      <TextField
                        style={{ backgroundColor: "#ffffff" }}
                        {...params}
                        variant="outlined"
                        label=""
                        placeholder="Search by crop name"
                      />
                    )}
                  />
                  <Button
                    style={{ marginHorizontal: 20, width: 180 }}
                    onClick={() => {
                      this.handleTraining(
                        Object.keys(this.state.changedSinceLastTraining)
                      );
                    }}
                    className={classes.searchBar}
                    color={"action"}
                  >
                    {this.state.progress
                      ? (100 * this.state.progress).toFixed(3) + "%"
                      : "Train all"}
                  </Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 10,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  {numTotalImage + " images"}
                </Typography>
                <Typography
                  style={{
                    marginLeft: 10,
                    fontFamily: "Roboto",
                    fontSize: 10,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  {numTotalCrops + " crops"}
                </Typography>
                <Typography
                  style={{
                    marginLeft: 10,
                    fontFamily: "Roboto",
                    fontSize: 10,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  {numTotalProblems + " problems"}
                </Typography>
              </div>

              <div
                style={{
                  marginTop: 4,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontSize: 10,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  {minC + " - " + maxC + " C"}
                </Typography>
                <Typography
                  style={{
                    marginLeft: 10,
                    fontFamily: "Roboto",
                    fontSize: 10,
                    fontWeight: "normal",
                    fontStyle: "normal",
                    letterSpacing: 0,
                    textAlign: "center",
                    color: "#000000",
                  }}
                >
                  {minIter + " - " + maxIter + " iter"}
                </Typography>
              </div>
            </AppBar>

            <div style={{ height: 650, width: 400, overflowY: "scroll" }}>
              <Typography
                style={{
                  fontFamily: "Roboto",
                  fontSize: 20,
                  fontWeight: "normal",
                  fontStyle: "normal",
                  letterSpacing: 0,
                  textAlign: "center",
                  color: "#000000",
                }}
              >
                <List
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  {sortedCrops.map((item, index) => {
                    return (
                      <ListItem
                        style={{
                          backgroundColor: "#ffffff",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                        }}
                      >
                        <ListItem
                          style={{
                            backgroundColor: "#ffffff",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          <ListItemText
                            primary={
                              this.state.changedSinceLastTraining.hasOwnProperty(
                                item
                              )
                                ? item + "*"
                                : item
                            }
                            secondary={""}
                          />
                          {this.props.firebase.auth.currentUser.uid ===
                            "Z7JhnzVdY4YMqJ5vcT2dTdNHaym1" && (
                            <div>
                              <Button
                                style={{ marginLeft: 10 }}
                                onClick={() => this.handleTraining([item])}
                                className={classes.searchBar}
                                color={"action"}
                              >
                                {"Train"}
                              </Button>
                              {this.state.classiferResults &&
                                this.state.classiferResults.hasOwnProperty(
                                  item
                                ) &&
                                this.state.classiferResults[item] && (
                                  <div>
                                    <ListItemText
                                      primary={
                                        this.state.classiferResults[
                                          item
                                        ].hasOwnProperty("accuracyChange")
                                          ? (
                                              100 *
                                              this.state.classiferResults[item]
                                                .accuracy
                                            ).toFixed(2) +
                                            "%" +
                                            "(" +
                                            (
                                              100 *
                                              this.state.classiferResults[item][
                                                "accuracyChange"
                                              ]
                                            ).toFixed(2) +
                                            "%" +
                                            ")"
                                          : (
                                              100 *
                                              this.state.classiferResults[item]
                                                .accuracy
                                            ).toFixed(2) + "%"
                                      }
                                      secondary={Timestamp(
                                        this.state.classiferResults[item]
                                          .dateForSorting
                                      )}
                                    />
                                    <ListItemText
                                      primary={""}
                                      secondary={
                                        this.state.classiferResults[
                                          item
                                        ].hasOwnProperty("best params")
                                          ? "C: " +
                                            this.state.classiferResults[item][
                                              "best params"
                                            ].C +
                                            " iter: " +
                                            this.state.classiferResults[item][
                                              "best params"
                                            ].max_iter
                                          : ""
                                      }
                                    />
                                    {this.state.classiferPremiumResults &&
                                      this.state.classiferPremiumResults.hasOwnProperty(
                                        item
                                      ) && (
                                        <ListItemText
                                          secondary={
                                            (
                                              100 *
                                              this.state
                                                .classiferPremiumResults[item]
                                                .accuracy
                                            ).toFixed(2) + "% (premium)"
                                          }
                                        />
                                      )}
                                  </div>
                                )}
                            </div>
                          )}
                        </ListItem>
                        <List
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <ListItem
                            style={{
                              backgroundColor: "#ffffff",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                            }}
                          >
                            {this.state.classiferResults &&
                              Object.keys(this.state.data[item]).map(
                                (item2, index) => {
                                  let alert =
                                    (this.state.diseaseInfoImages[item][
                                      item2
                                    ].hasOwnProperty("imageCluster") &&
                                      this.state.diseaseInfoImages[item][item2]
                                        .imageCluster.length ===
                                        this.state.diseaseInfoImages[item][
                                          item2
                                        ].numImages) ||
                                    (this.state.diseaseInfoImages[item][
                                      item2
                                    ].hasOwnProperty("plantPart") &&
                                      this.state.diseaseInfoImages[item][item2]
                                        .plantPart.length ===
                                        this.state.diseaseInfoImages[item][
                                          item2
                                        ].numImages);

                                  let subtitle =
                                    this.state.classiferResults.hasOwnProperty(
                                      item
                                    ) &&
                                    this.state.classiferResults[
                                      item
                                    ].hasOwnProperty("numImages")
                                      ? this.state.classiferResults[item][
                                          "numImages"
                                        ][item2] +
                                        " images | " +
                                        Timestamp(this.state.data[item][item2])
                                      : Timestamp(this.state.data[item][item2]);

                                  return (
                                    <ListItemText
                                      primary={
                                        alert ? item2 : item2 + "********"
                                      }
                                      secondary={subtitle}
                                    />
                                  );
                                }
                              )}
                            {this.state.listOfNotTrainedPathologies[item].map(
                              (item2, index) => {
                                return (
                                  <ListItemText
                                    style={{ color: "red" }}
                                    primary={item2}
                                  />
                                );
                              }
                            )}
                          </ListItem>
                        </List>
                      </ListItem>
                    );
                  })}
                  {allCropsWithImage.map((item, index) => {
                    if (this.state.data.hasOwnProperty(item) == false)
                      return (
                        <ListItem
                          style={{
                            backgroundColor: "#ffffff",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          <ListItemText primary={item} secondary={""} />
                          <List
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <ListItem
                              style={{
                                backgroundColor: "#ffffff",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                              }}
                            >
                              {this.state.listOfNotTrainedPathologies[item].map(
                                (item2, index) => {
                                  return (
                                    <ListItemText
                                      style={{ color: "red" }}
                                      primary={item2}
                                    />
                                  );
                                }
                              )}
                            </ListItem>
                          </List>
                        </ListItem>
                      );
                    else return null;
                  })}
                </List>
              </Typography>
            </div>
          </Paper>

          <Paper
            className={classes.paper}
            style={{ display: "flex", flexDirection: "column", margin: 20 }}
          >
            <AppBar
              className={classes.searchBar}
              position="sticky"
              color="default"
              elevation={0}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    margin: 12,
                  }}
                >
                  <Button
                    style={{ marginHorizontal: 20, width: 180 }}
                    onClick={this.handleCleanTraining}
                    className={classes.searchBar}
                    color={"action"}
                  >
                    {"Clean staging"}
                  </Button>

                  <Button
                    style={{ marginHorizontal: 20, width: 180 }}
                    onClick={this.handleStagingTraining}
                    className={classes.searchBar}
                    color={"action"}
                  >
                    {"Train staging"}
                  </Button>

                  <Button
                    style={{ marginHorizontal: 20, width: 180 }}
                    onClick={this.handleStagingToProduction}
                    className={classes.searchBar}
                    color={"action"}
                  >
                    {"Release"}
                  </Button>
                </div>
              </div>
            </AppBar>

            <div style={{ height: 650, width: 400, overflowY: "scroll" }}>
              <List
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {this.state.cropsInStagingProcess.map((item, index) => {
                  return (
                    <ListItem
                      style={{
                        backgroundColor: "#ffffff",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <ListItemText primary={item} secondary={""} />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          </Paper>
        </div>
      </div>
    );
  }
}

const condition = (authUser, workgroupID, userType, premiumLevel) => {
  return !(!authUser || userType !== "admin");
};

export default withAuthorization(condition)(withFirebase(AItrainingTool));
